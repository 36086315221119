

import Vue from "vue";
import { EMPTY_OPERATOR, IStation, IStationOperator } from "@common/station";

type ResolveFunction = (value: IStationOperator) => void;
type RejectFunction = (value: Error) => void;

export default Vue.extend({
	name: "EditOperatorDialog",
	data: () => ({
		dialog: false as boolean,
		resolve: null as ResolveFunction|null,
		reject: null as RejectFunction|null,
		operator: { ...EMPTY_OPERATOR } as IStationOperator,
		gasStations: [] as IStation[],
		hidePassword: true as boolean,
		valid: true,
		formRules: {
			password: [
				(v: string) => (/(^\d+$)|(^$)/g.test(v) || v === undefined || v === "") || "Password must contain only digits!",
				(v: string) => (v.length === 4 || v.length === 0) || "Password length must be exactly 4 digits!"
			]
		}
	}),
	methods: {
		open (operator: IStationOperator, gasStations: IStation[]): Promise<IStationOperator> {
			this.dialog = true;
			this.gasStations = gasStations;
			this.operator = { ...operator };
			if (this.gasStations.length) {
				this.operator.stationId = this.gasStations[0].id;
			}
			return new Promise((resolve, reject) => {
				this.resolve = resolve;
				this.reject = reject;
			});
		},
		clearPromise () {
			this.resolve = null;
			this.reject = null;
		},
		clearVars () {
			this.operator = { ...EMPTY_OPERATOR };
			this.hidePassword = true;
			this.valid = true;
		},
		agree () {
			if ((this.$refs.form as HTMLFormElement).validate()) {
				if (this.resolve) {
					this.resolve(this.operator);
				}
				this.dialog = false;
				this.clearPromise();
				this.clearVars();
			}
		},
		cancel () {
			if (this.reject) {
				this.reject(new Error("User Cancel"));
			}
			this.dialog = false;
			this.clearPromise();
			this.clearVars();
		},
	},
});
