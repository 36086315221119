
import Vue from "vue";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";
import ConfirmationDialog, { ConfirmationDialogOptions } from "@/components/common/ConfirmationDialog.vue";
import AddOperatorDialog from "@/components/operators/AddOperatorDialog.vue";
import EditOperatorDialog from "@/components/operators/EditOperatorDialog.vue";
import { IStation, IStationOperator, IStationOperatorDeleteRes, IStationOperatorUpdateRes } from "@common/station";

class TableHeader {
	constructor (
		public readonly text: string,
		public readonly align: string,
		public readonly sortable: boolean,
		public readonly value: string
	) {}
}

export default Vue.extend({
	name: "Operators",
	components: {
		"confirmation-dialog": ConfirmationDialog,
		"add-operator-dialog": AddOperatorDialog,
		"edit-operator-dialog": EditOperatorDialog
	},
	data: () => {
		return {
			search: "" as string,
			tableLoading: false as boolean,
			headers: [
				new TableHeader("Station", "start", true, "stationId"),
				new TableHeader("Username", "start", true, "username"),
				new TableHeader("Full Name", "start", true, "fullName"),
				new TableHeader("Actions", "start", false, "actions")
			],
			footerProps: {
				"items-per-page-options": [15, 30, 50, -1],
			},
			snackbar: {
				show: false as boolean,
				text: "" as string,
				color: "primary" as string,
			},
			gasStations: [] as IStation [],
		};
	},
	created: async function () {
		this.store.dispatch.changeAppTitle("Operators");
		this.tableLoading = true;
		try {
			const options: AxiosRequestConfig = {
				method: "GET",
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`
				},
				url: `${this.store.getters.serverURL}/station/all`,
			};
			const res: AxiosResponse<IServerRES<IStation[]>> = await axios(options);
			if (res.data.err === ServerError.NO_ERROR) {
				this.gasStations = res.data.payload.map((item) => {
					item.name = `(${item.id}) ${item.name}`;
					return item;
				});

				await this.store.dispatch.fetchOperators(this.gasStations);
			}
		} catch (err) {
			console.error(err);
		}
		this.tableLoading = false;
	},
	computed: {
		stationName () {
			return (stationId: string) => {
				for (const station of this.gasStations) {
					if (station.id === stationId) {
						return station.name;
					}
				}

				return "Unknown";
			};
		}
	},
	methods: {
		async addItem () {
			try {
				const data: IStationOperator = await (this.$refs.addOperator as InstanceType<typeof AddOperatorDialog>).open(this.gasStations);
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					data,
					url: `${this.store.getters.serverURL}/station/operator/add`,
				};
				const res: AxiosResponse<IServerRES<IStationOperatorUpdateRes>> = await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					if (res.data.payload.succeeded) {
						const { operator } = res.data.payload;
						this.store.dispatch.addOperator(operator);
						this.snackbar = {
							show: true,
							color: "success",
							text: "Operator was succesfully added.",
						};
					} else {
						this.snackbar = {
							show: true,
							color: "error",
							text: "Error! Try again!",
						};
					}
				} else {
					this.snackbar = {
						show: true,
						color: "error",
						text: "Error! Try again!",
					};
				}
			} catch (err) {
				if (err.message !== "User Cancel") {
					console.error(err);
					this.snackbar = {
						text: err.message,
						color: "error",
						show: true,
					};
				}
			}
		},
		async editItem (item: IStationOperator) {
			try {
				const data: IStationOperator = await (this.$refs.editOperator as InstanceType<typeof EditOperatorDialog>).open(item, this.gasStations);
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					data,
					url: `${this.store.getters.serverURL}/station/operator/update`,
				};
				const res: AxiosResponse<IServerRES<IStationOperatorUpdateRes>> = await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					if (res.data.payload.succeeded) {
						const { operator } = res.data.payload;
						this.store.dispatch.editOperator({
							old: item,
							new: operator
						});
						this.snackbar = {
							show: true,
							color: "success",
							text: "Operator was succesfully edited.",
						};
					} else {
						this.snackbar = {
							show: true,
							color: "error",
							text: "Error! Try again!",
						};
					}
				}
			} catch (err) {
				if (err.message !== "User Cancel") {
					console.error(err);
					this.snackbar = {
						text: err.message,
						color: "error",
						show: true,
					};
				}
			}
		},
		async deleteItem (item: IStationOperator) {
			try {
				const dialogTitle = "Operator delete";
				const dialogMessage = `Are you sure you want to delete the operator: "${item.fullName}"?`;
				const dialogOptions: ConfirmationDialogOptions = {
					color: "error",
					width: 400,
					agree: {
						text: "Delete",
						color: "error",
						icon: "delete_forever",
					},
					cancel: {
						text: "Cancel",
						color: "secondary",
						icon: "close",
					}
				};
				const deleteConfirmation = await (this.$refs.deleteOperator as InstanceType<typeof ConfirmationDialog>).open(
					dialogTitle,
					dialogMessage,
					dialogOptions
				);
				if (deleteConfirmation) {
					const data: IStationOperator = item;
					const options: AxiosRequestConfig = {
						method: "POST",
						headers: {
							Authorization: `Bearer ${localStorage.getItem("token")}`
						},
						data,
						url: `${this.store.getters.serverURL}/station/operator/delete`,
					};
					const res: AxiosResponse<IServerRES<IStationOperatorDeleteRes>> = await axios(options);
					if (res.data.err === ServerError.NO_ERROR) {
						if (res.data.payload.succeeded) {
							this.store.dispatch.deleteOperator(item);
						}

						this.snackbar = {
							show: true,
							color: "success",
							text: "Operator was succesfully deleted.",
						};
					}
				}
			} catch (err) {
				if (err.message !== "User Cancel") {
					console.error(err);
					this.snackbar = {
						text: err.message,
						color: "error",
						show: true,
					};
				}
			}
		},
	}
});
